import axios from 'axios';
import apiClient from '../middlewear';
import Cookies from 'js-cookie';
import {getUserId,getAuthToken} from "../utils/userSession"




export const login = (data:any) => {
  return apiClient.post(`/user/login`,data)
}

export const userProfileData = () => {
  const userId = getUserId();
  return apiClient.get(`user/${userId}`)
}

export const editUserData = (body: any) => {
  const userId = getUserId();
  return apiClient.put(`user/${userId}`,body)
}

export const signUp = (data:any) => {
  return apiClient.post(`/user/create/request/otp`,data)
}

export const postOtp = (data:any) => {
  return apiClient.post(`/user/create/token?token=${data?.token}&otp=${data?.otp}`,data)
}

export const setUserPassword = (data:any) => {
  const userId = getUserId();
  const setPswToken = Cookies.get('setPswToken')
  return axios.put(`${process.env.REACT_APP_API_URL}/user/${userId}/resetpassword`,{password:data?.password},{
    headers: {
      brandId: process.env.REACT_APP_API_BRAND_ID,
      Authorisation: setPswToken
    }
  })
}

export const forgotrequestOtp = (data:any) => {
  return apiClient.post(`/user/forgot/request/otp`,data)
}

export const resetrequestOtp = (data:any) => {
  return apiClient.post(`/user/forgot/verify/otp`,data);
}  

export const getLobby = (data:any) => {
    return apiClient.post(`/rgs/wat/lobby`,data);
};
  
export const gamesData = (data:any) => {
    return apiClient.post('/rgs/wat/games', data);
};

export const depositeHistory = (data:any) => {
  return apiClient.post(`/payment/user/history?pageNumber=${data?.pageNumber}&itemPerPage=${data?.itemPerPage}`,data)
}

export const addDeposite = (data:any) => {
  const userId = getUserId();
  return apiClient.post(`/payment/deposit?userId=${userId}`,data)
}

export const allusertransaction = (data:any) => {
  const userId = getUserId();
  return apiClient.post(`/wallet/user/${userId}/transaction?pageNumber=${data?.pageNumber}&itemPerPage=${data?.itemPerPage}`,data)
}

export const alluserbet = (data:any) => {
  return apiClient.post(`/rgs/wat/bets?pageNumber=${data?.pageNumber}&itemsPerPage=${data?.itemPerPage}`,{})
}

export const getuserInfo = () => {
  const userId = getUserId();
  return apiClient.get(`/user/${userId}`)
}

export const getPromotionBanner = () => {
  return apiClient.get(`/cms/banner`)
}

export const getPaymentChannel = (data:any) => {
  return apiClient.post('/payment/channel/all',data)
}

export const addBank = (data:any) => {
  const userId = getUserId();
  return apiClient.post(`/payment/bank/details?userId=${userId}`,data)
}

export const isPrimary = (data:any) => {
  const userId = getUserId();
  return apiClient.post(`/payment/bank/primary?userId=${userId}`,data)
}

export const getBankAccts = () => {
  const userId = getUserId();
  return apiClient.get(`/payment/bank/details?userId=${userId}&accountType=BANK`)
}

export const postProfileIcon = (data:any) => {
  const userId = getUserId();
  const authToken = getAuthToken();
  return axios.post(`${process.env.REACT_APP_API_URL}/user/profilephoto/${userId}`,data,{
    headers: {
      'Content-Type': 'multipart/form-data',
      brandId: process.env.REACT_APP_API_BRAND_ID,
      Authorisation: authToken
    },
  })
}

export default apiClient;