import React, { useEffect, useState } from 'react'
import styles from './mytransaction.module.scss'
import { allusertransaction, depositeHistory } from '../../api/api'
import Cookies from 'js-cookie'
import Navbar from '../navbar/Navbar'
import Loading from '../ui/loading/Loading'
import PaginationComp from '../pagination/PaginationComp'
import HistoryIcon from '../ui/images/HistoryIcon'
import NoResult from '../ui/images/NoResult'
import { Helmet } from 'react-helmet'

const MyTransaction = () => {
  const [loading, setLoading] = useState(true);
  const [transactionData, setTransactionData] = useState([])
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [activeFilter, setActiveFilter] = useState<string | null>("ALL");
  const [transType,setTransType]=useState("")
  const filters = [
    "ALL","DEBIT","CREDIT"
  ]
 
  let userId: any = Cookies.get("userId")
  let parseUserId = parseInt(userId)


  const fetchAllTransaction = async (page: any) => {
    const res = await allusertransaction({
      pageNumber: page-1,
      itemPerPage: 15,
      transactionType:transType
    })
    try {
      if (res?.status === 200) {
        setTransactionData(res?.data?.data?.data);
        setLoading(false)
        const totalRecords = (Math.ceil((res?.data?.data?.totalRecords / 15))) ?? 0
        setTotalPages(totalRecords);
      }

    } catch (error) {
      console.log(error, "error");

    }
  }

  const handleFilterClick = (fname:string) => {
    setTransType(fname)
    if (fname === "CONFIRMED" || "FAILED" || "DECLINED") {
      setPageNumber(1)
    }
    setActiveFilter(fname);
    if (fname === "ALL") {
      setTransType("")
    }
  }

  useEffect(() => {
    fetchAllTransaction(pageNumber)
  }, [pageNumber,transType])


  return (
    <>
      <Helmet>
        <title>{"Rapid Games My Transaction"}</title>
        <meta name="description" content="Manage and review all your transactions on Rapid Games. Access a comprehensive history of deposits, withdrawals, and gaming payments. Stay updated with real-time transaction details and maintain control over your gaming finances with our secure and user-friendly platform." />
        <meta name="keywords" content="my transactions, user transactions, transaction history, gaming payments, deposit records, withdrawal records, secure transaction platform, payment history, account transactions, financial management, gaming finance tracker, Rapid Games transactions" />
      </Helmet>

      <main className={styles.deposite_box_main_wrap}>
        <div className={styles.nav_GCont}>
          <Navbar name={"My Transaction"} />
        </div>
        <div className={styles.filters}>
        {filters?.map((fname:string,i:any)=>{
          return (
            <div className={`${activeFilter === fname ? styles.filter_field_active : styles.filter_field}`} onClick={()=>handleFilterClick(fname)} key={i}>{fname}</div>
          )
        })}
      </div>
        {
          loading ? <Loading /> :
            (transactionData?.length === 0 ? <div className={styles.noresult_wrap}>
              <NoResult />
              <p className={styles.noresult}>No Withdraw Found</p>
            </div> : (
              transactionData?.map((item: any) => {
                const updatedDate = new Date(item.updatedAt);
                const date = updatedDate.toLocaleDateString('en-IN');
                const time = updatedDate.toLocaleTimeString('en-IN', { hour: '2-digit', minute: '2-digit' });
                return <section className={styles.deposite_box}>
                  <div className={styles.deposite_left_side}>
                    <div className={styles.name_wrap}>
                      <HistoryIcon />
                      <h2 className={styles.payt_name}>
                        {item?.transactionType}</h2>
                    </div>
                    <p>Balance Amount</p>
                    <p>Transaction ID</p>
                    <p>{date} <span>{time}</span></p>
                  </div>

                  <div className={styles.deposite_right_side}>
                    <h2 className={styles.amt}>₹ {item?.amount / 100}</h2>
                    <p>₹ {item?.balance / 100}</p>
                    <p className={styles.transId}>{item?.transactionId}</p>
                    <p className={`${item.status === "FAILED" ? styles.failed : item.status === "CONFIRMED" ? styles.confirm : item.status === "DECLINED" ?
                      styles.declined : ""
                      }`}>{item?.description.length > 30 ? item.description.slice(0,30) + "..." : item?.description}</p>
                  </div>
                </section>
              })
            )

            )}
        {
          transactionData.length !== 0 ?
            <PaginationComp
              totalPages={totalPages}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber} /> : ""
        }
      </main>
    </>
  )
}

export default MyTransaction

