import React, { useState } from 'react';
import styles from "./login.module.scss";
import axios from 'axios';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { login } from '../../api/api';
import logo from "../../assets/images/logo.png"
import userIcon from "../ui/images/UserIcon"
import UserIcon from '../ui/images/UserIcon';
import LockIcon from '../ui/images/LockIcon';
import EyeIcon from '../ui/images/EyeIcon';
import OpenEye from '../ui/images/OpenEye';
import Logo from '../ui/images/Logo';


export interface LoginData {
  userName: string;
  password: string;
}

const Login = () => {
 const [hide,setHide]=useState(true)
  const navigate = useNavigate();

  const [loginData, setLoginData] = useState<LoginData>({
    userName: '',
    password: '',
  });

  const [errorMsg, setErrorMsg] = useState({
    userErr: false,
    passwordErr: false,
  });

  const [errMsgres,setErrMsgres] = useState("")

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setErrorMsg({
      userErr: false,
      passwordErr: false,
    });

    let hasError = false;

    // Validate username
    if (!loginData.userName) {
      setErrorMsg((prev) => ({ ...prev, userErr: true }));
      hasError = true;
    }

    // Validate password
    if (!loginData.password) {
      setErrorMsg((prev) => ({ ...prev, passwordErr: true }));
      hasError = true;
    }
    
    if (hasError) return;

    try {
      const res = await login(loginData);
      if (res.status === 200) {
        toast.success("login successfull")
        Cookies.set("accessToken", res?.data?.data?.accessToken);
        Cookies.set("userId", res?.data?.data?.UserId);
        localStorage.setItem("userId", res?.data?.data?.UserId);
        navigate('/', { replace: true });
      } else if (res.status === 400) {
        setErrMsgres(res.data.message);
      } else {
        console.log("Login unsuccessful");
      }
    } catch (error:any) {
      const errorMessage = error.response?.data?.message || "An error occurred during login";
      setErrMsgres(errorMessage);
      toast.error(errorMessage);
    }
  };

  const handleShowClick = () =>{
    setHide(!hide)
  }

  const onHandleRoute = (route:any) => {
    navigate(route);
  }

  return (
    <form className={styles.login_main_wrap} onSubmit={handleSubmit}>
      <div className={styles.logn_sub}>
      {/* <div>
        <Logo/>
      </div> */}
      <h1 className={styles.login}>Login</h1>
      <section className={styles.input_section}>
        <div className={styles.username}>
          <span><UserIcon/></span>
          <input 
            type='text' 
            name="userName"
            className={styles.logInp}
            id="userName"
            placeholder='Username'
            value={loginData.userName}
            onChange={handleInputChange} 
          />
          
        </div>
        {errorMsg.userErr && <span className={styles.errormsg}>please enter a username</span>}
        {errMsgres && <span className={styles.errormsg}>{errMsgres === "INVALID_USER_NAME" ? "invalid username" : ""}</span>}
        <div className={styles.password}>
          <span><LockIcon/></span>
          <input 
            type={hide ? "password" : "text"}
            name="password"
            className={styles.logInp}
            id="password"
            placeholder='Password'
            onChange={handleInputChange}
            value={loginData.password} 
          />
          <span className={styles.eyeIcon} onClick={handleShowClick}>{hide ? <EyeIcon/>: <EyeIcon/>}</span>
          
        </div>
        {errorMsg.passwordErr && <span className={styles.errormsg}>please enter a valid password</span>}
        {errMsgres && <span className={styles.errormsg}>{errMsgres === "INVALID_PASSWORD" ? "invalid password ": ""}</span>}
      </section>
      <div className={styles.fp_cont}>
      <p className={styles.fp_lf}><input className={styles.fp_checkBox} type="checkbox" /><span className={styles.fpText1}>Remember password</span></p>
      <p className={styles.fpText2} onClick={() => onHandleRoute("/forgotpassword")}>Forgot Password?</p>
      </div>
      </div>

      <section className={styles.btn_section}>
        <p className={styles.terms} onClick={() => onHandleRoute("/privacypolicy")}>T&C & Privacy Policy</p>
        <button className={styles.lg_btn}>Login</button>
        <div className={styles.a_cont}>
        <p className={styles.aText}>Don't have an account? <span className={styles.signIn} onClick={() => onHandleRoute("/signup")}>Sign Up</span></p>
        </div>
      </section>
    </form>
  );
}

export default Login;
